<template>

    <a-layout class="yj-conten-div">
        <a-layout-content>
            <div class="yj-conten" style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)">
                <a-table class="yj-table-img"
                         bordered :columns="columns2"
                         :data-source="taskDetaildata"
                         :pagination="pagination"
                         @change="pagerChange">
                    <span slot="imgs" slot-scope="text,record">
                        <img class="imgPreview" :src="text" alt="" @click="jumpshare(record)" />
                    </span>
                    <span slot="CustomerUserName" slot-scope="text,record">
                        <a @click="jumpshare(record)">{{record.CustomerUserName}}</a>
                    </span>
                    <span slot="Name" slot-scope="text,record">
                        <a @click="jumpshare2(record)">{{record.Name}}</a>
                    </span>

                    <a-button type="primary" @click="taskDetailList" style="display:none"></a-button>
                </a-table>

            </div>
        </a-layout-content>
    </a-layout>

</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns2: [
                    {
                        title: "头像",
                        dataIndex: "CustomerUserImg",
                        align: "center",
                        scopedSlots: { customRender: "imgs" },
                        width: 100
                    },
                    {
                        title: "昵称",
                        dataIndex: "CustomerUserName",
                        width: 200,
                        ellipsis: true,
                        scopedSlots: { customRender: "CustomerUserName" }

                    },
                    {
                        title: "电话",
                        dataIndex: "CustomerUserPhone",
                    },
                    {
                        title: "分享人",
                        dataIndex: "Name",
                        scopedSlots: { customRender: "Name" }
                    },
                    {
                        title: "时间",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "摘要",
                        dataIndex: "Summary",
                    }
                ],
                taskDetaildata: [],
                name: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
            };
        },
        methods: {
            jumpshare: function (data) {
                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.UserID
                    }
                })
            },
            jumpshare2: function (data) {
                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.TargetUserID
                    }
                })
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.taskDetailList();
            },
            taskDetailList: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetPromateStimulateShareInfo",
                    data: {
                        pageIndex: self.pagination.current,
                        ID: self.ID,
                    },
                    OnSuccess: function (data) {
                        self.taskDetaildata = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            RowClick: function (e) {
                console.log(e);
            },
            radioChange: function (item) {
                var self = this;
                self.category = item.target.value;
                self.taskDetailList();
            },
        },
        mounted() {
            var self = this;
            self.ID = self.$route.query.ID;
            this.taskDetailList();
        },
        beforeMount() {
            var self = this;
            var Category = this.$route.query.Category;
            if (!util.isBlank(Category)) {
                self.category = Category.toString();
            } else {
                self.category = "-1"
            }
        },
        computed: {},
        components: {}
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>